export const Environment = {
    /**
     * Define a quantidade de linhas a ser carregada por padrão nas listagens
     */
    LIMITE_DE_LINHAS: 50,

    /**
     * Placeholder exibido nas inputs
     */
    INPUT_DE_BUSCA: 'Pesquisar...',

    /**
     * Texto exibido quando nenhum registro é encontrado em uma listagem
     */
    LISTAGEM_VAZIA: 'Nenhum registro encontrado.',

    /**
     * Url base de consulta dos dados dessa aplicação
     */
    URL_BASE: 'https://clientesapi.whizconnect.com.br/api',
}